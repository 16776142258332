import axios from 'axios';
// import {MessageBox} from 'element-ui'  //引入element ul中的组件

const request = axios.create({
    // baseURL:'/', //默认路径
    baseURL:'https://a.tzfli.com/', //默认路径
    timeout:8000
})

//请求拦截器
request.interceptors.request.use((config)=>{
    
    //config 发送给后端的数据
    // config.headers = { 'X-Requested-With': 'XMLHttpRequest' }

    let token = sessionStorage.getItem("token")
    if(token){
        config.headers.token = token
    }

    return config
})

//响应拦截器
request.interceptors.response.use((response)=>{

    
    //response 后端发送过来的数据

    // if(response.data.code != 1){
        // MessageBox.alert("请联系管理员","加载超时",{
        //     confirmButtonText:"确定",
        //     type:'error'
        // }).then(()=>{
        //     localStorage.removeItem('token');
        //     window.location.reload();
        // })





        // alert("加载超时，请联系管理员")
        // return Promise.reject("error")
        // 调用 reject 方法
    // }   

    return response.data
},()=>{
    // console.log(res);
    
})


export default request